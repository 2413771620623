<template>
  <v-container fluid pa-0>

    <v-row class="pa-1">
      <v-col>
        <!--        <v-combobox :label="uqfmodel.variable"  v-if="uqfmodel.permitUnknownValues" v-model="selectedItems" :items="suggestions" multiple chips deletable-chips :delimiters="[';']" :search-input.sync="search">-->
        <!--          <template v-slot:no-data>-->
        <!--            <v-list-item>-->
        <!--              <v-list-item-content>-->
        <!--                <v-list-item-title  v-if="search!==null && search.length>0">-->
        <!--                  {{uqfmodel.variable}} "<strong>{{ search }}</strong>" eksisterer ikke. Tryk retur for at tilføje alligevel.-->
        <!--                </v-list-item-title>-->
        <!--                <v-list-item-title v-else>-->
        <!--                  Indtast værdi for {{uqfmodel.variable}}-->
        <!--                </v-list-item-title>-->
        <!--              </v-list-item-content>-->
        <!--            </v-list-item>-->
        <!--          </template>-->
        <!--        </v-combobox>-->
        <!--        <v-autocomplete v-else :label="uqfmodel.variable" v-model="selectedItems" :items="suggestions" multiple chips deletable-chips :delimiters="[';']" :search-input.sync="search"/>-->
        <v-autocomplete @change="onChange" :label="uqfmodel.title ? uqfmodel.title : uqfmodel.variable" v-model="selectedItems" :items="suggestions" multiple chips deletable-chips :delimiters="[';']" :search-input.sync="search">

          <template v-slot:item="data">
            <template v-if="typeof data.item !== 'object'">
              <v-list-item-content v-text="data.item"></v-list-item-content>
            </template>
            <template v-else>

              <template v-if="data.item.unknown">
                <v-list-item-content v-if="data.item.unknown">
                  <v-list-item-title v-html="data.item.text + ' ' + $t('ADD_ANYWAY')"></v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon>
                  <v-icon >mdi-plus</v-icon>
                  </v-list-item-icon>
              </template>
              <template v-else>
                <v-list-item-content>
                  <v-list-item-title v-html="data.item.text"></v-list-item-title>
                </v-list-item-content>
              </template>

              <!--              <v-list-item-content>-->
              <!--                <v-list-item-title v-html="data.item.text"></v-list-item-title>-->
              <!--                <v-list-item-subtitle v-html="data.item.value"></v-list-item-subtitle>-->
              <!--              </v-list-item-content>-->
            </template>
          </template>



        </v-autocomplete>
      </v-col>
    </v-row>

  </v-container>

</template>


<script>
import {mapGetters, mapState} from 'vuex'
import UsersService from "../../services/UsersService";

export default {
  name: 'UserQuestionFilterComponent',

  components: {
  },

  props: {
    uqfmodel: Object
  },

  data: () => ({
    search: null,
    selectedItems: [],
    suggestions: []
  }),

  created: function () {
    if(this.uqfmodel.value && this.uqfmodel.value.length>0) {
      this.selectedItems = this.uqfmodel.value.split(";")
      this.suggestions = this.uqfmodel.value.split(";")
    }
  },

  computed: {
    ...mapState(['activeSurvey']),
    ...mapGetters(['getAuthToken', 'getFilteredDrillPath']),
  },

  methods: {
    onChange() {
      this.uqfmodel.changed = true;
    },

  },

  watch: {
    search(searchTerm) {
      if(searchTerm!==null && searchTerm!=="") {
        UsersService.getQuestionFilterSuggestions(this.getAuthToken, this.uqfmodel.surveyName, this.uqfmodel.questionId, searchTerm).then((response) => {
          this.suggestions = [...this.selectedItems]
          this.suggestions.push(...response.data)
        });
      }
    },

    selectedItems(items) {
      const resultString = items.join(";")
      this.$emit('update-filters', resultString)
    }

  },

}
</script>
