<template>
    <v-container fluid class="ma-2" v-if="userEditorData">

        <v-row>
            <v-col cols="12">

                <v-card>
                    <v-form v-model="isValid" ref="form">
                        <v-container fluid>

                            <v-row>
                                <v-col cols="2">
                                    <v-text-field v-model="userEditorData.keyCloakData.email"  :label="$t('EMAIL')" required :rules="EmailRules" @change="onChangeEmail"/>
                                </v-col>
                                <v-col cols="2">
                                    <v-text-field v-model="userEditorData.keyCloakData.firstName"  :label="$t('FIRST_NAME')" required :rules="nameRules"/>
                                </v-col>
                                <v-col cols="2">
                                    <v-text-field v-model="userEditorData.keyCloakData.lastName"  :label="$t('LAST_NAME')" required :rules="nameRules"/>
                                </v-col>
                                <v-col cols="1" v-if="isUserRoot">
                                    <v-checkbox v-model="userEditorData.keyCloakData.enabled"  label="enabled"/>
                                </v-col>
                                <v-col cols="1">
                                    <v-checkbox v-model="userEditorData.indicators" label="indicators"/>
                                </v-col>
                                <v-col cols="1">
                                    <v-checkbox v-model="userEditorData.invite" label="invite"/>
                                </v-col>
                            </v-row>

                        </v-container>

                    </v-form>
                </v-card>
            </v-col>
        </v-row>

        <v-row v-if="fullAccess">
<!--            <v-row >-->
            <v-col>
                <KeyCloakUserComponent v-bind.sync="userEditorData.keyCloakData" @updatekeycloakdata="onUpdateKeyClockData"/>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <v-card>
                    <v-card-title>{{'SELECT' | i18n}}</v-card-title>
                    <v-card-text>
                        <div style="display: flex; flex-direction: row; gap: 2em;" >
                            <template v-for="(uniUser) in userEditorData.universeUsers" >
                                <v-checkbox v-if="uniUser.userId!==0" :label="uniUser.universeName" :key="uniUser.universeName" input-value="true" value disabled></v-checkbox>
                                <v-checkbox v-else :label="uniUser.universeName" :key="uniUser.universeName" v-model="uniUser.createuser"></v-checkbox>
                            </template>
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>


        <v-row>

            <template v-for="(uniUser) in userEditorData.universeUsers" >
                <UniverseUserComponent v-if="uniUser.userId!==0 || uniUser.createuser"
                                       v-bind.sync="uniUser"
                                       :full-access="fullAccess"
                                       @updateuqf="updateUQF(uniUser, $event)"
                                       @updateglobaluqf="updateGlobalUQF(uniUser, $event)"
                                       @update-channeldata="updateChannelData(uniUser, ...arguments)"
                                       ref="universeUserComponents" :key="uniUser.universeName"/>
            </template>

        </v-row>

      <v-row align-center justify-center v-if="validateErrors">
        <v-col>
          <v-icon color="#b9133e" class="mr-2">mdi-alert-circle-outline</v-icon>
          {{validateErrors.join(', ') }}
        </v-col>
      </v-row>

      <v-row>
            <v-col cols="2">
                <v-btn text @click="onSubmitClicked" :disabled="!isValid">
                    <template v-if="isNew">{{'CREATE' | i18n}}</template>
                    <template v-else>{{'UPDATE' | i18n}}</template>
                </v-btn>
                <v-btn text @click="onCancelClicked">{{'CANCEL' | i18n}}</v-btn>
            </v-col>
        </v-row>


    </v-container>

</template>


<script>
import {mapGetters, mapState} from 'vuex'
import UsersService from "../../services/UsersService";
import UniverseUserComponent from "@/views/users/UniverseUserComponent.vue";
import KeyCloakUserComponent from "@/views/users/KeyCloakUserComponent.vue";


export default {
    name: 'UserEditor',

    components: {
        KeyCloakUserComponent,
        UniverseUserComponent
    },

    data: () => ({
        isValid: false,

        userEditorData: null,
        userDetails: null,

        // repeatPassword: '',
        sendNotifications: null,

        rolesList: [],

        EmailRules: [
          v => !!v || 'Email is required'
        ],

        nameRules: [
            v => !!v || 'Name is required'
        ],

        roleRules: [
            v => !!v || 'Role is required'
        ],

        validateErrors: null

    }),

    created: function () {
        this.initialFetchData();
    },

    computed: {
        ...mapState(['activeSurvey']),
        ...mapGetters(['getAuthToken', 'getFilteredDrillPath', 'isUserRoot']),

        fullAccess() {
            return this.isUserRoot;
        },

        isNew() {
            return this.userEditorData && !this.userEditorData.keyCloakData.userId
        }

    },


    methods: {
        onChangeEmail(email) {

            UsersService.fetchUserEditorData(this.getAuthToken, this.activeSurvey.extName, email).then((response) => {

                UsersService.fetchKeyCloakData(this.getAuthToken, this.activeSurvey.extName, email).then((kcresponse) => {
                    this.userEditorData = response.data
                    this.userEditorData.keyCloakData = kcresponse.data
                })

            })
        },

      updateChannelData(uniUser, channelData, channelName, idx) {
        const surveyChannels = uniUser.notificationChannels[channelName]
        surveyChannels[idx] = channelData
      },

      updateGlobalUQF(uniUser, uqf) {

            for(const survey of uniUser.surveys) {
                for (const userVar of survey.userVariables) {
                    if (userVar.globalName === uqf.globalName) {
                        userVar.value = uqf.value
                    }
                }
            }

        },



        updateUQF(uniUser, uqf) {
            for(const survey of uniUser.surveys) {
                for (const userVar of survey.userVariables) {
                    if (userVar.questionId === uqf.questionId) {
                        userVar.value = uqf.value
                    }
                }
            }

        },

        onUpdateKeyClockData() {
            UsersService.fetchKeyCloakData(this.getAuthToken, this.activeSurvey.extName, this.userEditorData.keyCloakData.email).then((response) => {
                this.userEditorData.keyCloakData = response.data
            })
        },

        initialFetchData() {
            UsersService.fetchUserEditorData(this.getAuthToken, this.activeSurvey.extName, this.$route.params.userId).then((response) => {
                this.userEditorData = response.data
            })
        },

        onCancelClicked() {
            this.$router.push({name: 'users', params: {surveyExtName: this.activeSurvey.extName} })
        },

        onSubmitClicked() {
            if (this.$refs.form.validate()) {

                let minimalOneDashUser = false;

                for(const uu of this.userEditorData.universeUsers ) {
                    if(uu.createuser || uu.userId!==0) {
                        minimalOneDashUser = true;
                    }
                }

              this.validateErrors = []

                if(!minimalOneDashUser) {
                    this.validateErrors.push( "At least one universe must be associated with user" );
                }


                if(this.$refs.universeUserComponents) {
                  for (const qcomp of this.$refs.universeUserComponents) {
                    const errs = qcomp.validateMandatory();
                    this.validateErrors.push( ... errs )
                  }
                }

                if(this.validateErrors.length>0) {
                  return
                }

                UsersService.updateUserEditorData(this.getAuthToken, this.activeSurvey.extName, this.userEditorData).then((response) => {
                    let result = response.data
                    if (result.error) {
                        this.$store.commit('showSnackbar', "result.error")
                    } else {
                        this.$store.commit('showSnackbar', "user saved")
                        this.$router.push({name: 'users', params: {surveyExtName: this.activeSurvey.extName}})
                    }
                })
            }
        },

    },


}
</script>
