<template>
  <div>
    <v-select v-model="selectedOption" :items="filterOptions" @change="onFilterChange"></v-select>
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";

export default {
  name: 'FilterComponentTabelDate',

  data () {
    return {
      selectedOption: null,
   }
  },

  props: {
    filterSet: Object,
    filter: Object
  },

  created() {
    this.selectedOption = "none"
  },

  computed: {
    ...mapState(['activeSurvey']),
    ...mapGetters(['getFilterSelectedOptions', 'getActiveCombinedFilters']),

    filterOptions() {
      return [{value: 'none', text: ''}, {value: 'first', text: this.$t('TABLE_ROW_FIRST')}, {value: 'last', text: this.$t('TABLE_ROW_LAST')} ]
    },
  },

  methods: {
    onFilterChange() {

      if(this.selectedOption==="none") {
        this.$store.commit('clearFilter', {filterName: this.filter.extName, filterValue: null})
      } else {
        this.$store.commit('setFilter', {filterName: this.filter.extName, filterValue: [this.selectedOption]})
      }


      // const extname = "rdate" + this.filterDirection + this.deltaValue + this.deltaUnit + this.deltaDirection
      //
      // const filterValues = this.getActiveCombinedFilters.getFilterValuesForFilter(this.filter.extName);
      //
      // this.$store.commit('setFilter', {filterName: this.filter.extName, filterValue: [extname, ...filterValues]})
      //
      // this.checkedOptions = this.getActiveCombinedFilters.getFilterItems(this.activeSurvey.standardFilterSet)
      //     .filter(e => e.filter.extName===this.filter.extName)
      //     .map(e => e.option);
    },

    onCheckClicked(optionExtName) {
      // this.$store.commit('clearFilter', {filterName: this.filter.extName, filterValue: optionExtName})
    }

  },


}
</script>

<style>

.v-select-list .v-list .v-input__slot { margin-bottom: 0 !important; }

</style>
