<template>
  <div>

    <v-dialog v-model="showRecordingDialog" :max-width="600" style="{ z-index: 200 }">
      <audio ref="audioPlayer" controls autoplay muted>
        <source :src="callRecordingURL" type="audio/mpeg">
        Your browser does not support the audio element.
      </audio>
    </v-dialog>


    <div class="statsWrapper">
      <div class="statsBlock"><b>{{'MAILS' | i18n}}</b></div>

      <div class="statsBlock">
        <div class="statsLabel">{{'EMAIL' | i18n}}</div>
        <div class="statsValue">{{stats.email}}</div>
      </div>

      <div class="statsBlock">
        <div class="statsLabel">{{'TOTAL_SENT_EMAILS' | i18n}}</div>
        <div class="statsValue, text-right">{{stats.totalSent}}</div>
      </div>

      <div class="statsBlock">
        <div class="statsLabel">{{'COUNT_EMAILS_OPENED' | i18n}}</div>
        <div class="statsValue, text-right">{{stats.totalOpened}}</div>
      </div>

      <div class="statsBlock">
        <div class="statsLabel">{{'SHARE_EMAILS_OPENED' | i18n}}</div>
        <div class="statsValue, text-right">{{stats.shareOpened}}</div>
      </div>

      <div class="statsBlock">
        <div class="statsLabel">{{'COUNT_EMAILS_CLICKED' | i18n}}</div>
        <div class="statsValue, text-right">{{stats.totalClicked}}</div>
      </div>

    </div>

    <v-simple-table v-if="rows.length>0">
      <template v-slot:default>
        <thead>
        <tr>
          <th class="text-left">{{'DATE' | i18n}}</th>
          <th class="text-left">{{'MAIL_CAMPAIGN' | i18n}}</th>
          <th class="text-left">{{'STATUS' | i18n}}</th>
          <th class="text-right">{{'VIEWS' | i18n}}</th>
          <th class="text-right">{{'CLICKS' | i18n}}</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="item in rows" :key="item.id">
          <td>{{ item.date }}</td>
          <td>{{ item.name }}</td>
          <td>{{ item.state }}
            <v-btn v-if="item.recording" icon @click.stop="onPlayRecording(item)">  <v-icon small>mdi-volume-high</v-icon>  </v-btn>
          </td>
          <td class="text-right">{{ item.views }}</td>
          <td class="text-right">{{ item.clicks }}</td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>

  </div>


</template>

<script>


import MailsService from "@/services/MailsService";
import {mapGetters, mapState} from "vuex";

export default {
  name: 'MailTrackingsComponent',

  props: {
    mailTrackingsData: Object,
  },

  methods: {
    onPlayRecording(row) {
      this.playRecordingDialogId = row.id
      this.showRecordingDialog = true
    },

  },

  data: () => ({
    showRecordingDialog: false,
    playRecordingDialogId: null,
  }),

  computed: {
    ...mapState(['activeSurvey']),
    ...mapGetters(['getAuthToken', 'getFilteredDrillPath']),

    rows() {
      return this.mailTrackingsData.rows
    },

    stats() {
      return this.mailTrackingsData.stats
    },

    callRecordingURL() {
      return MailsService.callRecordingURL(this.getAuthToken, this.activeSurvey.extName, this.playRecordingDialogId)
    },

  }



}
</script>
<style scoped>
div.statsWrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

div.statsBlock {
  margin-right: 2rem;
}

div.statsLabel {
  font-size: 1rem;
}

div.statsValue {
  font-size: 1rem;
}

</style>