<template>
  <v-container fluid pa-3>
    <v-dialog   v-if="campaignDetails" v-model="infoDialog" :max-width="600" style="{ z-index: 200 }">
      <MailCampaignInfo :campaign-details="campaignDetails" :key="templateName"/>
    </v-dialog>

    <v-dialog v-if="campaignDetails" v-model="showRecordingDialog" :max-width="600" style="{ z-index: 200 }">
      <audio ref="audioPlayer" controls autoplay muted>
        <source :src="callRecordingURL" type="audio/mpeg">
        Your browser does not support the audio element.
      </audio>
    </v-dialog>

    <ConfirmDialog ref="confirm" />

    <v-row v-if="campaignDetails && isUserRoot">
      <v-col>
        <v-card>

          <div class="ma-2 statusLine">

            <div class="statusLeft">

              <div class="campaignNameDiv">
                {{campaignDetails.title}}
              </div>

              <div>
                <v-chip v-if="isPrepareState" class="ma-2" color="#00C896" text-color="white">{{'MAIL_CAMPAIGN_PREPARE' | i18n}}</v-chip>
                <v-chip v-else-if="isActiveState" class="ma-2" color="#00C896" text-color="white">{{'MAIL_CAMPAIGN_ACTIVE' | i18n}}</v-chip>
                <v-chip v-else-if="isPausedState" class="ma-2" color="yellow" text-color="black">{{'MAIL_CAMPAIGN_PAUSED' | i18n}}</v-chip>
                <v-chip v-else-if="isStoppedState" class="ma-2" color="grey" text-color="white">{{'MAIL_CAMPAIGN_STOPPED' | i18n}}</v-chip>
              </div>

              <div v-if="scheduledTime">
                <label>{{'MAIL_CAMPAIGN_SCHEDULE' | i18n}}: {{scheduledTime}}</label>
              </div>

            </div>

            <div class="statusRight">
              <div class="mr-4" v-if="isUserRoot">
                <v-btn @click="onEditClicked" :disabled="editorOpened">{{'MAIL_CAMPAIGN_EDIT' | i18n}}</v-btn>
              </div>
              <div>
                <v-btn v-if="canStart" color="#00C896" @click="onResumeClicked">{{'MAIL_CAMPAIGN_START' | i18n}}<v-icon>mdi-play</v-icon></v-btn>
                <v-btn v-if="canPause" color="orange" @click="onPauseClicked">{{'MAIL_CAMPAIGN_PAUSE' | i18n}}<v-icon>mdi-pause</v-icon></v-btn>
                <v-btn v-if="canResume" color="#00C896" @click="onResumeClicked">{{'MAIL_CAMPAIGN_RESUME' | i18n}}<v-icon>mdi-play</v-icon></v-btn>
              </div>
            </div>
          </div>

        </v-card>
      </v-col>
    </v-row>


    <v-row v-if="campaignDetails && isUserRoot && editorOpened">
      <v-col>
        <v-card>
          <v-form v-model="isValid">
            <v-container fluid>
              <v-row>
                <CampaignEditorComponent :campaign-id="campaignDetails.id" @editor-update="onEditUpdate" @editor-cancel="onEditCancel" />
              </v-row>
            </v-container>
          </v-form>
        </v-card>
      </v-col>
    </v-row>

    <v-row v-if="campaignDetails">
      <v-col>

        <v-data-table
            :search = "search"
            :headers="headers"
            :server-items-length="campaignDetails.numberOfTrackingRows"
            :items="rows"
            @update:options="onOptionsChanged"
            @click:row="onRowClicked"
            :options.sync="options"
            :loading="loading"
            item-key="id"
            class="elevation-2"
            :items-per-page="50"
            :footer-props="{
              showFirstLastPage:true,
              itemsPerPageOptions: [50, 100, 200],
            }"
            must-sort
            :sort-by.sync="sortBy"
            :show-select="enableDeleteTrackings"
            v-model="selectedRows"
            :single-select="false" >

          <template v-slot:top>
            <v-row class="align-center">
              <v-col cols="6">
                <v-text-field v-model="search" label="Filter" class="mx-4" @change="onFilterEnter"></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-btn :disabled="!canBeStopped" @click="onStopCampaign" class="mr-4" >{{'MAIL_CAMPAIGN_STOP' | i18n}}</v-btn>

                <v-btn @click="onDownloadClicked"  class="mr-4">{{'DOWNLOAD' | i18n}}</v-btn>
                <v-btn v-if="isLetterCampaign" @click="onDownloadLettersClicked(true)"  class="mr-4">{{'MAIL_CAMPAIGN_DOWNLOAD_ALL_LETTERS' | i18n}}</v-btn>
                <v-btn v-if="isLetterCampaign" @click="onDownloadLettersClicked(false)"  class="mr-4">{{'MAIL_CAMPAIGN_DOWNLOAD_NEW_LETTERS' | i18n}}</v-btn>

                <v-btn @click="onDeleteCampaignClicked">{{'DELETE' | i18n}}</v-btn>
              </v-col>
            </v-row>

            <v-row v-if="selectedRows.length>0">
              <v-col >
                <v-btn @click="onSelectedMail" class="ml-4">{{'MAIL' | i18n}}</v-btn>
                <v-btn @click="onSelectedDelete" class="ml-4">{{'DELETE' | i18n}}</v-btn>
              </v-col>
            </v-row>
          </template>

          <template v-slot:item.viewTemplate="{ item }">
            <v-dialog width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="viewButton" text v-bind="attrs" v-on="on">{{'MAIL_CAMPAIGN_PREVIEW' | i18n}}</v-btn>
              </template>
              <RenderedMail :campaign-id="campaignId" :template="templateRef" :tracking-id="item.id" :sending-type="campaignDetails.sendingType" :mail-action="campaignDetails.mailAction" />
            </v-dialog>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-btn icon @click.stop="onMailItem(item)" v-if="!isLetterCampaign" class="mr-2">  <v-icon small>mdi-email</v-icon>  </v-btn>
            <v-btn icon @click.stop="onDeleteItem(item)" v-if="enableDeleteTrackings">  <v-icon small>mdi-delete</v-icon>  </v-btn>
            <v-btn icon @click.stop="onDownloadItem(item)" v-if="isLetterCampaign">  <v-icon small>mdi-download</v-icon>  </v-btn>
            <v-btn icon @click.stop="onPlayRecording(item)" v-if="isPhoneCampaign && item.recording">  <v-icon small>mdi-volume-high</v-icon>  </v-btn>

          </template>

        </v-data-table>

      </v-col>

    </v-row>


  </v-container>

</template>


<script>
import {mapGetters, mapState} from 'vuex'
import PageNavigator from "../../pagenavigator";
import MailsService from "@/services/MailsService";
import RenderedMail from "@/views/mailcampaigns/RenderedMail";
import ConfirmDialog from "@/components/ConfirmDialog";
import MailCampaignInfo from "@/views/mailcampaigns/MailCampaignInfo";
import ReportsService from "@/services/ReportsService";
import CampaignEditorComponent from "@/views/mailcampaigns/CampaignEditorComponent.vue";

export default {
  name: 'MailCampaignDetails',

  components: {
    CampaignEditorComponent,
    MailCampaignInfo,
    ConfirmDialog,
    RenderedMail
  },

  props: {
    campaignIdParam: Number,
    showInfoDialog: Boolean,
    componentConfigId: String
  },

  created: function () {
    this.fetchCampaign()
  },

  data: () => ({
    campaignDetails: null,
    infoDialog: false,

    showRecordingDialog: false,
    playRecordingDialogId: null,

    isValid: false,

    mailTemplates: [],
    selectedTemplate: null,

    editorOpened: false,

    options: {},
    dialog: false,

    isLoadingTemplates: false,

    search: '',

    loading: false,

    rows: [],

    sortBy: 'id',

    selectedRows: [],

    nameRules: [
      v => !!v || 'Name is required'
    ],

    customHeaders: []

  }),


  computed: {
    ...mapState(['activeSurvey']),
    ...mapGetters(['getAuthToken', 'getFilteredDrillPath', 'isUserRoot']),

    callRecordingURL() {
      return MailsService.callRecordingURL(this.getAuthToken, this.activeSurvey.extName, this.playRecordingDialogId)
    },

    enableDeleteTrackings() {
      return this.campaignDetails.campaignType==='PULL_SINGLE'
    },

    isLetterCampaign() {
      return this.campaignDetails.sendingType==='LETTER'
    },

    isPhoneCampaign() {
      return this.campaignDetails.sendingType==='PHONE'
    },

    canStart() {
      return this.isPrepareState
    },

    canPause() {
      return this.isActiveState
    },

    canResume() {
      return this.isPausedState
    },

    isPrepareState() {
      return this.campaignDetails.state==='CREATED'
    },

    isActiveState() {
      return this.campaignDetails.state==='STARTED'
    },

    isPausedState() {
      return this.campaignDetails.state==='PAUSED'
    },

    isStoppedState() {
      return this.campaignDetails.state==='STOPPED'
    },

    headers() {
//      customHeaders

      // const serverHeaders = [  {name: 'nAvn', value: 'respondent' }, {name: 'eMail', value: 'email' } ]
      const serverHeaders = this.customHeaders

      // 'text': header.name, value: header.value, align: 'left'
      const dynHeaders = serverHeaders.map( header => ({ text: header.name, value: 'columnValues.'+header.value, align: 'left'  })  )

/*      const dynHeaders = [
      { text: this.$t('NAME'), align: 'left', value: 'respondent' },
      { text: this.$t('EMAIL_FOR_CAMPAIGN'), align: 'left', value: 'email' }
      ]
*/
      return [ ...dynHeaders , ...this.fixedHeaders ]
    },

    fixedHeaders() {
      return [

        { text: this.$t('DATE'), align: 'left', value: 'updatedTime' },
        { text: this.$t('STATE_FILTER'), align: 'left', value: 'status' },
        { text: this.$t('MAIL_CAMPAIGN_PREVIEW'), align: 'left', value: 'viewTemplate' },
        { text: this.$t('VIEWS'), align: 'left', value: 'openCount' },
        { text: this.$t('CLICKS'), align: 'left', value: 'clickCount' },
        { text: this.$t('ACTIONS'), value: 'actions', sortable: false },
      ];
    },

    campaignTypes() {
      return [
        { text: this.$t('MAIL_CAMPAIGN_TYPE_ONE_SHOT'), value: 'PULL_ONCE' },
        { text: this.$t('MAIL_CAMPAIGN_TYPE_AUTO_ADD'), value: 'PULL' },
        { text: this.$t('MAIL_CAMPAIGN_TYPE_PUSH'), value: 'PUSH' },
      ];
    },

    schedulePeriodItems() {
      return [
        { text: this.$t('MAIL_CAMPAIGN_DAILY'), value: 'DAILY' },
        { text: this.$t('MAIL_CAMPAIGN_WEEKLY'), value: 'WEEKLY' },
        { text: this.$t('MAIL_CAMPAIGN_MONTHLY'), value: 'MONTHLY' },
      ];
    },

    scheduledTime() {
      return this.campaignDetails.scheduledStartDateTime
    },

    campaignId() {
      return this.campaignIdParam ? this.campaignIdParam : this.$route.query.campaignId
    },

    templateRef() {
      return ""+this.campaignDetails.templateId
    },

    canBeStarted() {
      return this.campaignDetails.canStart
    },

    canBeStopped() {
      return this.campaignDetails.canStop
    },

    mandatoryRules() {
      return  [
        value => !!value || this.$t('VALIDATE_ERROR_MANDATORY'),
      ]
    },

    templateName() {
      return this.campaignDetails ? this.campaignDetails.mailTemplate : "?"
    },

  },


  methods: {
    onEditClicked() {
      this.editorOpened = true;
    },

    onEditUpdate() {
      this.editorOpened = false;
      this.fetchCampaign()
      this.onOptionsChanged(this.options)
    },

    onEditCancel() {
      this.editorOpened = false;
    },

    fetchCampaign() {
      MailsService.fetchMailCampaignData(this.getAuthToken, this.activeSurvey.extName, this.campaignId).then((response) => {
        this.campaignDetails = response.data
        this.selectedTemplate = this.campaignDetails.mailTemplate
        this.mailTemplates = [this.selectedTemplate]
      })
    },

    onPauseClicked() {
      MailsService.pauseCampaign(this.getAuthToken, this.activeSurvey.extName, this.campaignId).then((/*response*/) => {
        this.fetchCampaign()
      })

    },

    onResumeClicked() {
      MailsService.resumeCampaign(this.getAuthToken, this.activeSurvey.extName, this.campaignId).then((/*response*/) => {
        this.fetchCampaign()
      })
    },

    fetchMailCampaignData() {
      MailsService.fetchMailCampaignData(this.getAuthToken, this.activeSurvey.extName, this.campaignId).then((response) => {
        this.campaignDetails = response.data
      })
    },

    onUpdateClicked() {
      this.campaignDetails.mailTemplate = this.selectedTemplate

      MailsService.updateMailCampaignData(this.getAuthToken, this.activeSurvey.extName, this.campaignId, this.campaignDetails).then((/*response*/) => {
        this.fetchMailCampaignData();
        this.$store.commit('showSnackbar', this.$t("MAIL_CAMPAIGN_UPDATED"))
      })

    },

    async onStartCampaign() {
      if ( await this.$refs.confirm.open("Confirm", "Start campaign?")) {
        MailsService.startMailCampaign(this.getAuthToken, this.activeSurvey.extName, this.campaignId).then((/*response*/) => {
          this.fetchMailCampaignData()
          this.onOptionsChanged(this.options)
        })
      }
    },

    goToList() {
      const fromPage = this.$route.query.fromPage

      if(fromPage) {
        PageNavigator.navigateToPage(this, fromPage, this.activeSurvey.extName,  this.getFilteredDrillPath)
      } else {
        this.$emit('exit-details')
      }
    },

    onRowClicked(row) {
      PageNavigator.navigateToInterviewPage(this, this.activeSurvey.extName, row.sampleId)
    },

    onOptionsChanged(options) {
      const pageNumber = options.page
      const pageSize = options.itemsPerPage

      const from = (pageNumber-1)*pageSize
      const to = from + pageSize

      const sortBy = options.sortBy[0].startsWith('columnValues.') ?
          options.sortBy[0].substring( 'columnValues.'.length ) : options.sortBy[0]

      const sortDesc = options.sortDesc[0]

      MailsService.fetchMailTrackingRows(this.getAuthToken, this.activeSurvey.extName, this.campaignId, this.componentConfigId, from, to, sortBy, sortDesc, this.search).then((response) => {
        this.customHeaders = response.data.headers
        this.rows = response.data.rows
      })
    },

    onFilterEnter() {
      this.onOptionsChanged(this.options)
    },

    onSelectedMail() {
      const trackingIds = this.selectedRows.map( row => row.id)
      MailsService.sendMails(this.getAuthToken, this.activeSurvey.extName, this.campaignId, trackingIds).then((/*response*/) => {
        this.fetchMailCampaignData();
        this.onOptionsChanged(this.options)
      })
    },

    async onMailItem(row) {
      if ( await this.$refs.confirm.open("Confirm", this.$t("MAIL_CAMPAIGN_RESEND_CONFIRM_MSG"))) {
        MailsService.sendMails(this.getAuthToken, this.activeSurvey.extName, this.campaignId, [row.id]).then((/*response*/) => {
          this.fetchMailCampaignData();
          this.onOptionsChanged(this.options)
        })
      }
    },

    onSelectedDelete() {
      const trackingIds = this.selectedRows.map( row => row.id)
      MailsService.deleteMailTracking(this.getAuthToken, this.activeSurvey.extName, this.campaignId, trackingIds).then((/*response*/) => {
        this.fetchMailCampaignData();
        this.onOptionsChanged(this.options)
      })
    },

    onDeleteItem(row) {
      MailsService.deleteMailTracking(this.getAuthToken, this.activeSurvey.extName, this.campaignId, [row.id]).then((/*response*/) => {
        this.fetchMailCampaignData();
        this.onOptionsChanged(this.options)
      })
    },

    onDownloadItem(row) {
      window.open(ReportsService.letterDownloadURL(this.getAuthToken, this.activeSurvey.extName, row.id), '_blank')
    },

    onPlayRecording(row) {
      this.playRecordingDialogId = row.id
      this.showRecordingDialog = true
    },

    onCancelClicked() {
      this.goToList()
    },

    async onStopCampaign() {
      if ( await this.$refs.confirm.open("Confirm", "Stop campaign?")) {
        MailsService.stopMailCampaign(this.getAuthToken, this.activeSurvey.extName, this.campaignId).then(() => {
          this.fetchMailCampaignData()
        })
      }
    },

    async onDeleteCampaignClicked() {
      if ( await this.$refs.confirm.open("Confirm", "Delete campaign?")) {
        MailsService.deleteMailCampaign(this.getAuthToken, this.activeSurvey.extName, this.campaignId).then(() => {
          this.goToList()
        })
      }
    },

    onDownloadClicked() {
      window.open(ReportsService.mailCampaignReportDownloadURL(this.getAuthToken, this.activeSurvey.extName, this.campaignId, this.componentConfigId), '_blank')
    },

    onDownloadLettersClicked(allLetters) {
      ReportsService.createLettersDownloadTask(this.getAuthToken, this.activeSurvey.extName, this.campaignId, allLetters).then((response) => {
        ReportsService.downloadInitiated(this.getAuthToken, this.$store, this.$t, this.activeSurvey.extName, response.data.taskUUID)
        this.$store.commit('showSnackbar', this.$t("PREPARING_REPORT_SNACK"))
      })
    },


  },

  watch: {
    showInfoDialog: function() { // watch it
      this.infoDialog = true
    },

    showRecordingDialog: function (newValue) {
      if(newValue) {
        this.$refs['audioPlayer'].load()
      } else  {
        this.playRecordingDialogId = null
        this.$refs['audioPlayer'].pause()
      }
    }

  }


}
</script>

<style scoped>

div.statusLine {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

div.statusLeft {
  display: flex;
  flex-direction: row;
  align-items: center;
}

div.statusRight {
  display: flex;
  flex-direction: row;
  align-items: center;
}


div.campaignNameDiv {
  font-size: 140%;
  font-weight: bold;
}

.statsLabel {
  font-weight: bold;
  margin-right: 4px;
}

.viewButton {
  text-transform: unset !important;
  font-weight: normal !important;
}

</style>
