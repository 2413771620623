<template>
  <v-card outlined>
    <v-container class="py-2 px-4" fluid>
      <v-row>
        <v-col cols="2">
          <div class="headline">{{interviewData.state}}</div>
        </v-col>
        <template v-for="(backgroundVariable, idx) in backgroundVariables">
          <v-col  cols="2" :key="'bv_' + idx">
            <VariableComponent :label="backgroundVariable.label" :value="backgroundVariable.value" />
          </v-col>
        </template>
        <v-col :cols="12 - 2 - (2*backgroundVariables.length)" style="float: right; text-align: right">
          <table>
            <tr>
              <td style="width: 100%">&nbsp;</td>
              <td> <BackgroundDetailsDialog :interview-data="interviewData" /> </td>
            </tr>
          </table>

        </v-col>
      </v-row>

      <template v-if="interviewData.openQuestionsData.length > 0">
        <v-divider class="mt-3 mb-5"/>
        <v-row>
          <v-col cols="12">
            <div
                v-for="openQuestion in interviewData.openQuestionsData"
                :key="openQuestion.label"
                class="openQuestionSection px-8 pt-4"
            >
              <div class="label">
                {{ openQuestion.label }}
              </div>
              <div class="value">
                &ldquo;{{ openQuestion.value}}&rdquo;
              </div>
            </div>
          </v-col>
        </v-row>

      </template>
      <v-divider class="mt-3 mb-5"/>

      <v-row>
        <v-col cols="12">
          <StaredQuestionsComponent :starred-questions="starredQuestions" v-if="starredQuestions && starredQuestions.length > 0"/>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" class="mt-4 mb-0">
          <div class="headline">{{ "RESULTS" | i18n }}</div>
        </v-col>

      </v-row>
      <v-row>
        <v-col cols="6" class="interviewOverviewColumn" style="border-right: 1px solid grey;">
          <InterviewResultGroupComponent v-if="resultGroupsData && resultGroupsData.length > 0"
                                         :question-groups="resultGroupsData"
                                         :selected-group="getSelectedResultGroup"
                                         :use-number-icons="useNumberIcons"
                                         @questionGroupClicked="changeSelectedResultGroup"
          />
        </v-col>
        <v-col cols="6">
          <InterviewAnswersComponent :answers="selectedResultGroup.answers" :use-number-icons="useNumberIcons" v-if="selectedResultGroup"/>
        </v-col>
      </v-row>

      <v-row v-for="group in interviewData.tableGroups" v-bind:key="group.extname">
        <v-col cols="12">
          <RecordGroupComponent :question-group="group"/>
        </v-col>
      </v-row>

      <v-divider class="mb-16 mt-6" />

      <v-row v-if="interviewData.comments">
        <v-col cols="12">
          <InterviewCommentsComponent :interview-id="interviewData.interviewId" :comments-list="interviewData.comments"/>
        </v-col>
      </v-row>

      <v-row v-for="group in interviewData.recordGroups" v-bind:key="group.extname">
        <v-col cols="12">
          <RecordGroupComponent :question-group="group"/>
        </v-col>
      </v-row>

      <v-row v-if="interviewData.mailTrackingsData">
        <v-col cols="12">
          <MailTrackingsComponent :mail-trackings-data="interviewData.mailTrackingsData"/>
        </v-col>
      </v-row>

    </v-container>

  </v-card>
</template>

<script>

import InterviewAnswersComponent from "./InterviewAnswersComponent";
import InterviewResultGroupComponent from "./InterviewResultGroupComponent";
import RecordGroupComponent from "./RecordGroupComponent";
import VariableComponent from "./VariableComponent";
import BackgroundDetailsDialog from "./BackgroundDetailsDialog";
import StaredQuestionsComponent from "./StarredQuestionsComponent";
import MailTrackingsComponent from "@/views/interview/MailTrackingsComponent";
import InterviewCommentsComponent from "@/views/interview/InterviewCommentsComponent.vue";

export default {
  name: 'InterviewComponent',

  components: {
    InterviewCommentsComponent,
    MailTrackingsComponent,
    BackgroundDetailsDialog,
    InterviewAnswersComponent,
    InterviewResultGroupComponent,
    RecordGroupComponent,
    StaredQuestionsComponent,
    VariableComponent,
  },

  data: () => ({
    resultGroups: [
      {
        label: 'Satisfaction and loyalty',
        answers: [
          { label: 'Question 1', value: 'A1', icon: 'red-3'},
          { label: 'Question 2', value: 'A2', icon: 'red-3'},
          { label: 'Question 3', value: 'A3', icon: 'green-4'},
          { label: 'Question 4', value: 'A4', icon: 'green-4'},
          { label: 'Question 5', value: 'A5', icon: 'green-4'},
          { label: 'Question 6', value: 'A text answer', icon: 'black-textAnswered'},
          { label: 'Question 7', value: 'A7', icon: 'green-5'},
        ]
      },
      {
        label: 'The shop',
        answers: [
          { label: 'Question yes/no', value: 'yes', icon: 'green-checked'},
          { label: 'Question 2', value: 'A2', icon: 'green-5'},
        ]
      },
      {
        label: 'Satisfaction with the car',
        answers: [
          { label: 'Question 1', value: 'Lorem lip sum', icon: 'white-textAnswered'},
          { label: 'Question 2', value: 'Etiam ultrices ligula', icon: 'black-textAnswered'},
          { label: 'Question 3', value: null, icon: 'dark-textMissing'},
          { label: 'Question 4', value: null, icon: 'black-textMissing'},
        ]
      },
      {
        label: 'Electric Car',
        answers: []
      },
      {
        label: 'Presented to Service Advisor',
        answers: [
          { label: 'Question 1', value: null, icon: 'grey-missing'},
          { label: 'Question 2', value: null, icon: 'red-x'},
        ]
      }
    ],
    selectedResultGroup: null
  }),

  created() {
    this.selectedResultGroup = this.resultGroupsData[0]
  },

  props: {
    interviewData: Object
  },

  methods: {
    changeSelectedResultGroup(selected) {
      this.selectedResultGroup = selected
    }
  },

  computed: {
    useNumberIcons() {
      return this.interviewData!=null && this.interviewData.showInternalValues
    },

    starredQuestions() {
      return this.interviewData ? this.interviewData.starredQuestionsData : []
    },


    resultGroupsData() {
      return this.interviewData ? this.interviewData.resultGroupsData : []
    },

    backgroundVariables() {
      if (this.interviewData) {
        let backgroundVariables = []
        for (const variableBlock of this.interviewData.highlights) {
          if (variableBlock.type === 'background') {
            backgroundVariables = backgroundVariables.concat(variableBlock.data)
          }
        }
        return backgroundVariables
      } else {
        return []
      }
    },
    getSelectedResultGroup() {
      return this.selectedResultGroup ? this.selectedResultGroup : (this.resultGroups ? this.resultGroups[0] : null)
    },
    getAnswersOfSelectedGroup() {
      return this.selectedResultGroup ? this.selectedResultGroup.answers : []
    }
  }

}
</script>
<style scoped>

.subHeadLine {
  /*font-size: 14px;*/
  font-size: calc((14/16)*1rem);
}

.headerRight {
  color: rgba(0,0,0,0.87);
  /*font-size: 24px;*/
  font-size: calc((24/16)*1rem);
  font-weight: 500;
  text-align: right;
}

.interviewOverviewColumn {
  border-right: thin solid rgba(0, 0, 0, 0.12);
}

.openQuestionSection .label {
  /*font-size: 14px;*/
  font-size: calc((14/16)*1rem);
  color: rgba(0,0,0,0.54) ;
}

.openQuestionSection .value {
  /*font-size: 16px;*/
  font-size: calc((16/16)*1rem);
}

</style>